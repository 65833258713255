import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Involvement,
  Visit,
  TitleContent,
  Youtube,
  ExternalLink,
  WorkedWith,
} from "../components/parts"

const RealLeaderInterviews = () => (
  <Layout title="RealLeader interviews">
    <Title
      heading="RealLeader interviews"
      sub="50+ interviews with everyday leaders"
    />
    <Overview
      project="real-leaders"
      text={[
        "The Real Leaders Project was an initiative to share stories of leaders among us.",
        "Between September 2011 and June 2014, we shared interviews with more than 60 leaders, including leading authors, venture capitalists, researchers, musicians, and athletes.",
      ]}
    />
    <TitleContent title="Interviews">
      <ExternalLink
        href="https://alearningaday.blog/2012/03/26/richard-stallman-founder-of-free-software-foundation-interview-xiii-real-leader-interviews/"
        label="Richard stallman (RMS), Founder of Free Software Foundation"
      />
      <Youtube src="https://www.youtube.com/embed/sM8B4uFYnjU" />
      <Youtube src="https://www.youtube.com/embed/uh8ptuCu928" />
      <Youtube src="https://www.youtube.com/embed/UrP7biER-yE" />
      <Youtube src="https://www.youtube.com/embed/IoevBZzxgUg" />
    </TitleContent>
    <Visit project="real-leaders" />
    <Involvement project="real-leaders" />
    <WorkedWith project="real-leaders" />
  </Layout>
)

export default RealLeaderInterviews
